<template>
    <div class="market-body">
        <div class="mark-title"> {{detailData.taskDesc}}</div>
        <van-cell title="检查类型" is-link arrow-direction="down"
                  :value="activityTypeTxt" placeholder="请选择"
                  @click="detailData.status==='finished'?showActivityType=false:showActivityType=true"/>
        <van-popup v-model="showActivityType" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="activityType"
                    @cancel="showActivityType = false"
                    @confirm="selectActivityType"
            />
        </van-popup>
        <van-cell  title="完成村委走访">
            <van-switch v-model="submitPar.visitVillageCommittee" size='20'
                        active-color="#417C53" :disabled="detailData.taskStatus==='finished'" />
        </van-cell>
        <van-cell title="村委走访时间" is-link arrow-direction="down"
                  :value="submitPar.activityTime" v-if="submitPar.visitVillageCommittee"
                  @click="detailData.taskStatus==='finished'?showActivityTime=false:showActivityTime=true"/>
        <van-calendar v-model="showActivityTime"
                      :min-date="minDate"
                      color="#417C53"
                      @confirm="onConfirm" />
        <van-cell  title="完成村民排查">
            <van-switch v-model="submitPar.visitVillager" size='20'
                        active-color="#417C53" :disabled="detailData.taskStatus==='finished'" />
        </van-cell>
        <van-cell title="村民排查时间" is-link arrow-direction="down"
                  :value="submitPar.activityTime2" v-if="submitPar.visitVillager"
                  @click="detailData.taskStatus==='finished'?showActivityTime2=false:showActivityTime2=true"/>
        <van-calendar v-model="showActivityTime2"
                      :min-date="minDate"
                      color="#417C53"
                      @confirm="onConfirm2" />

        <van-field v-model="submitPar.participant" :disabled="detailData.taskStatus==='finished'" label="参与人" placeholder="请输入"/>

        <van-cell>
            <div>图片上传</div>
            <van-uploader class="margin-l-20"
                          max-count="5"
                          v-model="fileList"
                          :after-read="afterRead"
                          :before-delete="deletePic"
                          :disabled="detailData.taskStatus==='finished'"

            />
        </van-cell>
        <van-cell style="margin-bottom: 80px;">
            <div>检查情况</div>
            <van-field
                    v-model="submitPar.activityCondition"
                    rows="5"
                    autosize
                    type="textarea"
                    placeholder="请输入"
                    style="border:1px solid #7E8185"
                    :disabled="detailData.taskStatus==='finished'"

            />
        </van-cell>

        <div class="nextBtn flex" >
            <van-button class="publicBtnActive left-btn"
                        v-if="detailData.taskStatus !== 'finished'"
                        @click="saveReport"
            >暂存</van-button>
            <van-button class="publicBtnActive"
                        style="margin-right: 0.24rem;"
                        v-if="detailData.taskStatus !== 'finished'"
                        @click="submitReport"
            >提交</van-button>
            <!--
            <van-button class="publicBtnActive"
                        v-if="detailData.taskStatus === 'finished'"
                        @click="newlyReport"
            >新增</van-button>-->
        </div>

    </div>

</template>

<script>
    import {getMyTask,saveFileInfo,saveMasterCareTask,addSpecialTask}  from '@/utils/api';
    import common from "@/utils/common";
    import { Toast } from 'vant'
    export default {
        name: "specialTask.vue",
        data(){
            return{
                minDate: new Date(2000, 0, 1),
                showActivityTime:false,
                showActivityTime2:false,
                submitPar:{
                    activityTime:'',//时间
                    activityTime2:'',//时间
                    participant:'',//参与人
                    activityType:'',
                    activityCondition:'',//情况
                    visitVillageCommittee:false,
                    visitVillager:false
                },
                activityType:[
                    {text:'请选择',value:''},
                    {text:'走访村委/村民排查',value:'visitingVillageCommittee'}
                ],
                activityType2:{
                    ' ':'请选择',
                    'visitingVillageCommittee':'走访村委/村民排查'
                },
                pic:[],//图片
                activityTypeTxt:'',//任务类型text
                showActivityType:false,
                fileList:[],
                detailData:[],
                newDate:'',
                newTaskNo:'',
            }
        },
        created() {
            //获取当前时间
            let myDate = new Date();
            let startY = myDate.getFullYear();
            let startM = myDate.getMonth() + 1;
            this.newDate = startY +'-'+ (startM < 10 ? '0' + startM :startM);
            this.getTaskDetail();
        },
        methods:{
            formatter(value) {
                // 过滤输入的数字
                return value.replace(/\./, '');
            },
            selectActivityType(value){
                this.activityTypeTxt= value.text;
                this.submitPar.activityType = value.value;
                this.showActivityType = false;
            },
            //获取详情
            getTaskDetail(taskNo){
                this.pic = [];
                this.fileList = [];
                let par = '';
                if(taskNo){
                    par = taskNo
                }else{
                    par = this.$route.query.taskNo;
                }
                getMyTask({taskNo:par}).then(res=>{
                    console.log('详情', res);
                    if(res.code === 200 && res.data.businessCode === 200){
                        this.detailData= res.data.taskInfo;
                        let taskContent =  res.data.taskInfo.taskContent;
                        console.log('taskContent', taskContent);
                        if(taskContent){
                            let data = JSON.parse(taskContent);
                            this.activityTypeTxt = this.activityType2[data.activityType],//任务类型text
                            this.submitPar = {
                                activityTime:data.activityTime,//活动时间
                                activityTime2:data.activityTime2,//活动时间
                                participant:data.participant,//参与人
                                activityType:data.activityType,//类型
                                activityCondition:data.activityCondition,//活动情况
                                visitVillageCommittee:data.visitVillageCommittee,
                                visitVillager:data.visitVillager
                            }
                            console.log('pic:'+data.pic);
                            console.log('submitPar:',this.submitPar);
                            this.pic = JSON.parse(data.pic)//图片
                            this.handlePic(this.pic);
                        }else{
                            this.activityTypeTxt = '',//任务类型text
                                this.submitPar = {
                                    activityTime:'',//活动时间
                                    activityTime2:'',//活动时间
                                    participant:'',//参与人
                                    activityType:'',//类型
                                    activityCondition:'',//活动情况
                                    visitVillageCommittee:false,
                                    visitVillager:false
                                }
                        }
                    }

                })

            },
            //处理回显图片
            handlePic(imgUrlArr){
                let url ='' ;
                for(let i= 0; i< imgUrlArr.length; i++){
                    if(process.env.NODE_ENV === 'production'){
                        url = window.location.origin + '/file/' + imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                    }else{
                        url = 'http://124.70.167.116:20081/file/' +  imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                    }
                    this.fileList.push({url:url})
                }
            },
            //选择时间
            formatDate(date) {
                return `${date.getFullYear()}-${(date.getMonth() + 1)
                < 10 ?'0'+(date.getMonth() + 1):(date.getMonth() + 1)}-${date.getDate() < 10 ? '0'+date.getDate():date.getDate()}`;
            },
            onConfirm(date) {
                this.showActivityTime = false;
                this.submitPar.activityTime = this.formatDate(date);
            },
            onConfirm2(date) {
                this.showActivityTime2 = false;
                this.submitPar.activityTime2 = this.formatDate(date);
            },

            afterRead(file, detail){
                // 大于15M
                if(file.file.size > 15 * 1024 * 1024){
                    Toast('图片太大，请上传小于15M的图片')
                    return
                }
                //验证图片格式
                if(!common.imageVerify(file.file.type)){
                    this.fileList.splice(detail.index, 1)
                    Toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                if(file.file.size < 100 * 1024){
                    this.saveFile(file.content)
                    return
                }
                //读取文件内容
                let reader = new FileReader();
                //方法可以将读取到的文件编码成DataURL,可以将资料(例如图片,excel文件)内嵌在网页之中，不用放到外部文件
                reader.readAsDataURL(file.file)
                let _this = this
                reader.onload = function () {
                    let content= file.content //图片的src，base64格式
                    let img = new Image()
                    img.src = content
                    // _this.perviewImage = content;
                    img.onload = function() {
                        common.compress(img, (dataURL)=>{
                            _this.saveFile(dataURL)
                        })
                    }
                }

            },
            deletePic (file, pic) {
                this.fileList.splice(pic.index,1)
                if (file.url){
                    this.pic.splice(pic.index,1)
                }
            },
            saveFile(dataURL) {
                let params = {
                    fileType:'specialTaskImg',
                    fileContent: dataURL,
                    stationNo:this.$route.query.stationNo,
                    picOwnNo: common.getItem('uniqueNo'),
                }
                saveFileInfo(params).then(res=>{
                    console.log('res', res);
                    if(res.code === 200 && res.data.businessCode === 200){
                        if(this.pic.length < 6){
                            this.pic.push(res.data.filePath)
                        }
                    }
                })
            },
            saveReport(){
                let newPar = this.newTaskNo ? this.newTaskNo : this.$route.query.taskNo;
                let data = {
                    taskNo:newPar,//必须 任务编号
                    stationNo:this.$route.query.stationNo,//站点编号（提交时传）
                    pic: this.pic.length > 0 ? JSON.stringify(this.pic):'',
                    ...this.submitPar,
                }
                console.log('data:', data)
                saveMasterCareTask(data).then(res=>{
                    console.log('提交汇报', res)
                    if(res.code === 200 && res.data.businessCode === 200){
                        this.$message.success('提交成功!')
                        this.getTaskDetail();
                    }
                })
            },
            submitReport(){

                if(!this.submitPar.activityType){
                    this.$message.warning('请选择检查类型!');
                    return;
                }
                if(!this.submitPar.visitVillageCommittee){
                    this.$message.warning('请完成村委拜访!');
                    return;
                }
                if(!this.submitPar.activityTime){
                    this.$message.warning('请选择村委拜访时间!');
                    return;
                }
                if(!this.submitPar.visitVillager){
                    this.$message.warning('请完成村民排查!');
                    return;
                }
                if(!this.submitPar.activityTime2){
                    this.$message.warning('请选择村民排查时间!');
                    return;
                }

                if(this.pic.length === 0 ){
                    this.$message.warning('请上传图片!');
                    return;
                }
                if(!this.submitPar.activityCondition){
                    this.$message.warning('请输入检查情况!');
                    return;
                }
                console.log('this.pic:', this.pic)
                console.log('JSON.stringify(this.pic):', JSON.stringify(this.pic))
                let newPar = this.newTaskNo ? this.newTaskNo : this.$route.query.taskNo;
                let data = {
                    taskNo:newPar,//必须 任务编号
                    stationNo:this.$route.query.stationNo,//站点编号（提交时传）
                    pic: this.pic.length > 0 ? JSON.stringify(this.pic):'',
                    isSubmit:'1',
                    ...this.submitPar,
                }
                console.log('data:', data)
                saveMasterCareTask(data).then(res=>{
                    console.log('提交汇报', res)
                    if(res.code === 200 && res.data.businessCode === 200){
                        this.$message.success('提交成功!')
                        this.getTaskDetail();
                    }
                })

            },
            //新增
            newlyReport(){
                this.newTaskNo = '';
                addSpecialTask({
                    stationNo:this.$route.query.stationNo,//站点编号
                }).then(res=>{
                    if(res.code === 200 && res.data.businessCode === 200){
                        this.getTaskDetail(res.data.data.taskNo);
                        this.newTaskNo = res.data.data.taskNo;
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .market-body{
        padding: 20px  10px 0  10px;
        .flex {
            display: flex;
        }
        .mark-title{
            font-size:15px;
            font-weight: bold;
        }
        .putInto-box{
            padding: 15px;
            display: flex;
            justify-content: space-between;
            .van-cell{
                padding:0;
            }
        }
        .margin-l-20{
            margin-left: 20px;
        }
        .nextBtn {
            width: 100%;
            padding: 12px;
            background: #f7f8f9;
            position: absolute;
            bottom: 0;
            left: 0;
            .left-btn{
                background: #fff;
                color:#333;
                border: 1px solid #E5E5E5;
                margin-right: 0.24rem;
            }
        }
        .m-t-10{
            margin:10px 5px;
            font-weight: bold;
        }
    }

</style>
